
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import User from "@/interfaces/User";
import CustomButton from "@/components/CustomButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import CheckBox from "@/components/CheckBox.vue";
import { suspendUser } from "@/services/adminService";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { AvatarImage, CustomButton, CustomInput, CheckBox },
  props: {
    data: {
      type: Object as PropType<{
        id: string;
        serverID: string;
        user: User;
        users: User[];
        callback: any;
      }>,
      required: true,
    },
  },
  data() {
    return {
      reason: "Violating the TOS.",
      password: "",
      requestSent: false,
      error: null as string | null,
    };
  },
  computed: {
    user(): any {
      return this.data.user;
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("admin-suspend-user-popout");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    buttonClicked() {
      if (this.requestSent) return;
      this.requestSent = true;
      this.error = null;
      if (this.data.users) {
        this.suspendBatch();
        return;
      }
      suspendUser(this.data.user.id, this.password, this.reason)
        .then(() => {
          this.data.callback();
          this.close();
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = "Could not connect to server.";
            return;
          }
          const { message } = await err.response.json();
          this.error = message;
        })
        .finally(() => (this.requestSent = false));
    },
    suspendBatch(index = 0) {
      const id = this.data.users[index].id;
      suspendUser(id, this.password, this.reason)
        .then(() => {
          this.data?.callback?.(this.data.users[index]);
          if (index + 1 === this.data.users.length) {
            this.close();
            return;
          }
          this.suspendBatch(index + 1);
        })
        .catch(async (err) => {
          this.requestSent = false;
          if (!err.response) {
            this.error = "Could not connect to server.";
            return;
          }
          const { message } = await err.response.json();
          this.error = message;
        });
    },
  },
});
